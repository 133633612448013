// Components
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const categoryHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${category}"`

  return (
    <Layout>
      <SEO title={categoryHeader} />
      <div className="article">
        <h1 className="text-4xl">{category}</h1>
        <ul className="flex flex-wrap -m-4 list-reset">
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            const { img } = node.frontmatter
            return (
              <li className="p-4 w-full md:w-1/2" key={title}>
                <Link to={slug}>
                  <Img sizes={img.childImageSharp.sizes} alt={title} />
                </Link>
              </li>
            )
          })}
        </ul>
        <div className="flex justify-center">
          <Button href="/art">Full Portfolio</Button>
        </div>
      </div>
    </Layout>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            img {
              childImageSharp {
                sizes(
                  maxWidth: 660
                  maxHeight: 660
                  background: "rgba(255,255,255,1)"
                  fit: CONTAIN
                ) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
